<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <!-- <div class="head">
        <div class="left">
          <b class="left_tit">标签列表</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div> -->
      <!-- 筛选部分 -->
      <!-- 表格头部 -->
 
      <!-- 表格渲染 -->
	  
      <div class="table">
        <!-- 设置表格高度  height="500" -->
		<div style="margin-left: -65%;margin-bottom: 10px;">会员>会员列表</div>
		<div class="bck">
			<div style="font-weight: bold; margin-left: -90%;margin-bottom: 20px;">会员列表</div>
        <el-table
          :data="labelList"
          border
          style="width: 95%;margin-left: 2.5%; text-align: center"
        >
		
		<!-- <el-table-column prop="ml_id" label="ID" align="center">
		</el-table-column> -->
          <el-table-column prop="m_id" label="用户ID" align="center">
          </el-table-column>
          <el-table-column prop="wx_name" label="微信昵称" align="center">
          </el-table-column>
          <el-table-column prop="headimgurl" label="用户头像" align="center">
			   <template slot-scope="scope">
			        <img  :src="scope.row.headimgurl" alt="" style="width: 50px;height: 50px">
			      </template>
          </el-table-column>
		  <el-table-column prop="store_name" label="归属商户" align="center">
		  </el-table-column>
		  <el-table-column prop="mg_name" label="会员等级" align="center">
		  </el-table-column>
		  <el-table-column prop="member_name" label="姓名" align="center">
		  </el-table-column>
      
          <el-table-column prop="member_tel" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="member_code" width="150px" label="会员卡号" align="center">
          </el-table-column>
         <el-table-column prop="integral" label="可用积分" align="center">
		
         </el-table-column>
          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
				<span
				  style="margin-left: 10px"
				  @click="detail(scope.row.m_id,scope.row.mg_name,scope.row.wx_name)"
				>
				  查看</span
				>
      <!--        <span
                style="margin-left: 10px"
                @click="
                  edit(
                    
                    scope.row.l_name,
                    scope.row.l_count,
                    scope.row.l_money,
					scope.row.mg_id,
					scope.row.storeid
                  )
                "
              >
                编辑
              </span> -->
              <span
                style="margin-left: 10px"
                @click="delActive(scope.row.m_id,)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
		<div class="block">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="currentPage4"
		    :page-sizes="[10]"
		    :page-size="pageSize"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="titlePage"
		  >
		  </el-pagination>
		  <div></div>
		</div>
		</div>
      </div>
      <!-- 分页 -->
     

    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "6-1",
      top2: ["6"],
      flag: 1,
      admin_type: "",
	  xstoreid:'',
      labelList:[],
      selects:'',
	  selectp:'',
      activeTitle: "",
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      businessList: [{}],
      business_id: "",
      storeList: [{}],
      storeid: "",
      sizeForm: {
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
		titlePage:10,
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
 
    //修改上下架
  
    //刷新页面按钮
    refresh() {
      location.reload();
    },
 
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.memberList({token,storeid:'',login_type:login_type,page:'1',limit:this.pageSize,tel:'',user_name:'',rdate:''}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
      
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.memberList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize,tel:'',user_name:'',rdate:''}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
     
    },
	switchChange(val)
	{
		let token = this.$storage.getLocal('token');
		let login_type=this.$storage.getLocal('type')
		this.$request.memberGradeCheckdeUp({token,mg_id:val}).then(r=>{
			if(r.code == 0){ 
		    this.$request.memberGradeList({token,storeid:'',login_type:login_type,page:'1',limit:'10'}).then(r=>{
		    	// console.log(123)
		    	if(r.code == 0){ 
		    		console.log(r)
		    		this.labelList=r.data.data
		    	}
		    })
			}
		})
	},
    delActive(val){
		console.log(val)
		let token = this.$storage.getLocal('token');
		this.$request.memberDel({token,m_id:val}).then(r=>{
			if(r.code == 0){ 
				location.reload();
			}
		})
	},
	detail(val,rank,nick)
	{
		this.$router.push({path:'/memberdetail?id='+val+'&rank='+rank+'&nick='+nick})
	},
    onSubmit() {
    let login_type=this.$storage.getLocal('type')
    let token = this.$storage.getLocal('token');
	this.$request.memberGradeAdd({
		token,
		storeid:this.xstoreid,
		login_type:login_type,
		g_name:this.labelList.g_name,
		g_val:this.labelList.g_val,
		is_checkde:this.labelList.is_checkde,
		freight:this.labelList.freight,
		comment:this.labelList.comment
		}).then(r=>{
		// console.log(123)
		if(r.code == 0){ 
			this.show=false
			location.reload();
		}
	})
    },
 
  shopType(value){
  	let token = this.$storage.getLocal('token');
  	this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
  		if(r.code == 0){ 
  			this.selectp = r.data;
  			console.log(r)
  		}
  	})
  },
  storeidType(value)
  {
  	this.xstoreid=value
  },
    //编辑活动的提交按钮
    onChange() {
      if (
        this.sizeForm2.title == "" 
   
      ) 
	  {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
 
      }
    },
    edit(name,count,money,id,storeid)
	{
		// console.log(val)
		this.show=true
		this.labelList.l_name=name
		this.labelList.l_count=count
		this.labelList.l_money=money
		this.labelList.storeid=storeid
		// this.labelList.storeid=storeid
	}
 
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
   let login_type=this.$storage.getLocal('type')
   let token = this.$storage.getLocal('token');
   this.$request.memberList({token,storeid:'',login_type:login_type,page:'1',limit:this.pageSize,tel:'',user_name:'',rdate:''}).then(r=>{
   	// console.log(123)
   	if(r.code == 0){ 
   		console.log(r)
		this.titlePage=r.data.count
		this.labelList=r.data.data
   	}
   })

   if(login_type=='1')
   {
   	this.$request.getMerchantData({token}).then(r=>{
   		if(r.code == 0){ 
   			this.selects = r.data;
   			console.log(r)
   	
   		}
   	})
   }else if(login_type=='2')
   {
   	this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
   		if(r.code == 0){ 
   			this.selectp = r.data;
   			console.log(r)
   		}
   	})
   }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 710px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 200px;
		border: 1px solid black;
	
	}
	.sel
	{
		/* margin-left: 10px; */
		margin-top: 5px;
	}
	.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
	
</style>